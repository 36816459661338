<template>
  <span :aria-label="ariaLabel">
    <v-text-field
      ref="field"
      v-bind="$attrs"
      :clearable="!readonly && clearable"
      :readonly="readonly"
      color="primary"
      class="em-form-field"
      :class="className"
      :variant="variant"
      :data-cy="dataCy"
    >
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-text-field>
  </span>
</template>

<script setup>
const $attrs = useAttrs()
defineProps({
  clearable: { type: Boolean, default: true },
  readonly: { type: Boolean, default: false },
  ariaLabel: { type: String, default: '' },
  variant: { type: String, default: 'outlined' }
})
const dataCy = computed(() => $attrs['data-cy-form-field'])
const className = computed(() => $attrs.class)
const field = ref()
const validate = () => field.value?.validate()
defineExpose({ className, validate })
</script>
